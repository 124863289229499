import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
// import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("citibot_support_chat");
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ToastContainer />
    <App accountId={rootElement.dataset.account_id} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
